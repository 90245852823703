import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  // Check if user is logged in and is an admin
  const user = JSON.parse(localStorage.getItem('user'));
  
  // You'll need to implement proper admin authentication
  const isAdmin = user && user.isAdmin === true;

  

  return children;
};

export default PrivateRoute;