import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from '../styles/ChatsManagement.module.css';

const ChatsManagement = () => {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalChats: 0
  });

  // Add token retrieval
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchChats = async () => {
      try {
        // Validate token exists
        if (!token) {
          throw new Error('No authentication token found');
        }

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/chats`, 
          {
            headers: { 
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            params: {
              page: pagination.currentPage
            }
          }
        );

        // Log full response for debugging
        console.log('Full API Response:', response.data);

        // Validate response structure
        if (!response.data || !response.data.chats) {
          throw new Error('Invalid response format');
        }

        // Update chats and pagination
        setChats(response.data.chats);
        setPagination({
          currentPage: response.data.currentPage || 1,
          totalPages: response.data.totalPages || 1,
          totalChats: response.data.totalChats || 0
        });

        setLoading(false);
      } catch (err) {
        // More detailed error logging
        console.error('Fetch chats error:', err);
        setError(err.response?.data?.message || err.message || 'Failed to fetch chats');
        setLoading(false);
      }
    };

    fetchChats();
  }, [token, pagination.currentPage]);

  const handleDeleteChat = async (chatId) => {
    try {
      // Validate token and chatId
      if (!token) {
        throw new Error('No authentication token found');
      }

      if (!chatId) {
        throw new Error('Invalid chat ID');
      }

      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/admin/chats/${chatId}`, 
        {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      // Update chats state by filtering out deleted chat
      setChats(prevChats => 
        prevChats.filter(chat => chat._id !== chatId)
      );

      // Update total chats count
      setPagination(prev => ({
        ...prev,
        totalChats: prev.totalChats - 1
      }));
    } catch (err) {
      // More detailed error logging
      console.error('Delete chat error:', err);
      setError(err.response?.data?.message || err.message || 'Failed to delete chat');
    }
  };

  const handlePageChange = (newPage) => {
    setPagination(prev => ({
      ...prev,
      currentPage: newPage
    }));
  };

  // Render error with more details
  if (error) {
    return (
      <div className={styles.errorContainer}>
        <h2>Error</h2>
        <p>{error}</p>
        <details>
          <summary>Troubleshooting Tips</summary>
          <ul>
            <li>Ensure your backend API is running</li>
            <li>Check your authentication token</li>
            <li>Verify network connectivity</li>
            <li>Check browser console for detailed errors</li>
          </ul>
        </details>
      </div>
    );
  }

  // Loading state
  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.spinner}></div>
        <p>Loading chats...</p>
      </div>
    );
  }

  // No chats found state
  if (chats.length === 0) {
    return (
      <div className={styles.noChatsContainer}>
        <h2>Chat Management</h2>
        <p>No chats found.</p>
      </div>
    );
  }

  return (
    <div className={styles.chatsManagement}>
      <h2>Chat Management</h2>
      <div className={styles.chatStats}>
        <p>Total Chats: {pagination.totalChats}</p>
        <p>Page {pagination.currentPage} of {pagination.totalPages}</p>
      </div>
      <table className={styles.chatTable}>
        <thead>
          <tr>
            <th>Sender</th>
            <th>Receiver</th>
            <th>Message</th>
            <th>Timestamp</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {chats.map(chat => (
            <tr key={chat._id}>
              <td>
                <div className={styles.userInfo}>
                  <span>{chat.sender?.name || 'Unknown'}</span>
                  <small>{chat.sender?.email || ''}</small>
                </div>
              </td>
              <td>
                <div className={styles.userInfo}>
                  <span>{chat.receiver?.name || 'Unknown'}</span>
                  <small>{chat.receiver?.email || ''}</small>
                </div>
              </td>
              <td>{chat.message || 'N/A'}</td>
              <td>
                {chat.timestamp 
                  ? new Date(chat.timestamp).toLocaleString() 
                  : 'Not available'}
              </td>
              <td>
                <button 
                  className={styles.deleteButton}
                  onClick={() => handleDeleteChat(chat._id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className={styles.paginationControls}>
        <button 
          onClick={() => handlePageChange(pagination.currentPage - 1)}
          disabled={pagination.currentPage <= 1}
        >
          Previous
        </button>
        <span>
          Page {pagination.currentPage} of {pagination.totalPages}
        </span>
        <button 
          onClick={() => handlePageChange(pagination.currentPage + 1)}
          disabled={pagination.currentPage >= pagination.totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ChatsManagement;