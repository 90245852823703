import React, { useState } from 'react';
import styles from '../styles/AdminPanel.module.css';
import UsersManagement from './UsersManagement';
import ChatsManagement from './ChatsManagement';
import MatchesManagement from './MatchesManagement';

const AdminPanel = () => {
  const [activeSection, setActiveSection] = useState('users');

  const renderSection = () => {
    switch(activeSection) {
      case 'users':
        return <UsersManagement />;
      case 'chats':
        return <ChatsManagement />;
      case 'matches':
        return <MatchesManagement />;
      default:
        return <UsersManagement />;
    }
  };

  return (
    <div className={styles.adminPanel}>
      <div className={styles.sidebar}>
        <h1>Admin Panel</h1>
        <nav>
          <button 
            className={activeSection === 'users' ? styles.active : ''} 
            onClick={() => setActiveSection('users')}
          >
            Users
          </button>
          <button 
            className={activeSection === 'chats' ? styles.active : ''} 
            onClick={() => setActiveSection('chats')}
          >
            Chats
          </button>
          <button 
            className={activeSection === 'matches' ? styles.active : ''} 
            onClick={() => setActiveSection('matches')}
          >
            Matches
          </button>
        </nav>
      </div>
      <div className={styles.content}>
        {renderSection()}
      </div>
    </div>
  );
};

export default AdminPanel;