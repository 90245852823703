import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from '../styles/MatchesManagement.module.css';

const MatchesManagement = () => {
  const [matches, setMatches] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalMatches: 0
  });
  const [showCreateMatch, setShowCreateMatch] = useState(false);
  const [newMatch, setNewMatch] = useState({
    fromUserId: '',
    toUserId: '',
    type: 'like'
  });
  const [editingMatch, setEditingMatch] = useState(null);

  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!token) {
          throw new Error('No authentication token found');
        }

        // Fetch matches
        const matchesResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/matches`,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            params: {
              page: pagination.currentPage
            }
          }
        );

        // Fetch users
        const usersResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/matches/users`,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );

        setMatches(matchesResponse.data.matches);
        setUsers(usersResponse.data);
        setPagination({
          currentPage: matchesResponse.data.currentPage || 1,
          totalPages: matchesResponse.data.totalPages || 1,
          totalMatches: matchesResponse.data.totalMatches || 0
        });

        setLoading(false);
      } catch (err) {
        console.error('Fetch data error:', err);
        setError(err.response?.data?.message || err.message || 'Failed to fetch data');
        setLoading(false);
      }
    };

    fetchData();
  }, [token, pagination.currentPage]);

  const handleCreateMatch = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/matches/create-match`,
        newMatch,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      setMatches(prev => [...prev, response.data]);
      setShowCreateMatch(false);
      setNewMatch({ fromUserId: '', toUserId: '', type: 'like' });
    } catch (err) {
      console.error('Create match error:', err);
      setError(err.response?.data?.message || err.message || 'Failed to create match');
    }
  };

  const handleUpdateMatch = async (matchId, updates) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/admin/matches/${matchId}`,
        updates,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      setMatches(prev =>
        prev.map(match =>
          match._id === matchId ? response.data : match
        )
      );
      setEditingMatch(null);
    } catch (err) {
      console.error('Update match error:', err);
      setError(err.response?.data?.message || err.message || 'Failed to update match');
    }
  };

  const handleDeleteMatch = async (matchId) => {
    try {
      if (!token) {
        throw new Error('No authentication token found');
      }

      if (!matchId) {
        throw new Error('Invalid match ID');
      }

      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/admin/matches/${matchId}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      setMatches(prevMatches =>
        prevMatches.filter(match => match._id !== matchId)
      );

      setPagination(prev => ({
        ...prev,
        totalMatches: prev.totalMatches - 1
      }));
    } catch (err) {
      console.error('Delete match error:', err);
      setError(err.response?.data?.message || err.message || 'Failed to delete match');
    }
  };

  const handlePageChange = (newPage) => {
    setPagination(prev => ({
      ...prev,
      currentPage: newPage
    }));
  };

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <h2>Error</h2>
        <p>{error}</p>
        <details>
          <summary>Troubleshooting Tips</summary>
          <ul>
            <li>Ensure your backend API is running</li>
            <li>Check your authentication token</li>
            <li>Verify network connectivity</li>
            <li>Check browser console for detailed errors</li>
          </ul>
        </details>
      </div>
    );
  }

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.spinner}></div>
        <p>Loading matches...</p>
      </div>
    );
  }

  return (
    <div className={styles.matchesManagement}>
      <h2>Matches Management</h2>
      
      <button 
        className={styles.createButton}
        onClick={() => setShowCreateMatch(!showCreateMatch)}
      >
        {showCreateMatch ? 'Cancel' : 'Create New Match'}
      </button>

      {showCreateMatch && (
        <form onSubmit={handleCreateMatch} className={styles.createMatchForm}>
          <select
            value={newMatch.fromUserId}
            onChange={(e) => setNewMatch(prev => ({ ...prev, fromUserId: e.target.value }))}
            required
          >
            <option value="">Select From User</option>
            {users.map(user => (
              <option key={user._id} value={user._id}>
                {user.name} ({user.email})
              </option>
            ))}
          </select>

          <select
            value={newMatch.toUserId}
            onChange={(e) => setNewMatch(prev => ({ ...prev, toUserId: e.target.value }))}
            required
          >
            <option value="">Select To User</option>
            {users.map(user => (
              <option key={user._id} value={user._id}>
                {user.name} ({user.email})
              </option>
            ))}
          </select>

          <select
            value={newMatch.type}
            onChange={(e) => setNewMatch(prev => ({ ...prev, type: e.target.value }))}
            required
          >
            <option value="like">Like</option>
            <option value="pass">Pass</option>
          </select>

          <button type="submit">Create Match</button>
        </form>
      )}

      <div className={styles.matchStats}>
        <p>Total Matches: {pagination.totalMatches}</p>
        <p>Page {pagination.currentPage} of {pagination.totalPages}</p>
      </div>

      <table className={styles.matchTable}>
        <thead>
          <tr>
            <th>From User</th>
            <th>To User</th>
            <th>Type</th>
            <th>Status</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {matches.map(match => (
            <tr key={match._id}>
              <td>
                <div className={styles.userInfo}>
                  <span>{match.fromUser?.name || 'Unknown'}</span>
                  <small>{match.fromUser?.email || ''}</small>
                </div>
              </td>
              <td>
                <div className={styles.userInfo}>
                  <span>{match.toUser?.name || 'Unknown'}</span>
                  <small>{match.toUser?.email || ''}</small>
                </div>
              </td>
              <td>
                {editingMatch === match._id ? (
                  <select
                    value={match.type}
                    onChange={(e) => handleUpdateMatch(match._id, { type: e.target.value })}
                  >
                    <option value="like">Like</option>
                    <option value="pass">Pass</option>
                  </select>
                ) : (
                  match.type || 'N/A'
                )}
              </td>
              <td>
                {editingMatch === match._id ? (
                  <select
                    value={match.status}
                    onChange={(e) => handleUpdateMatch(match._id, { status: e.target.value })}
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                ) : (
                  match.status || 'N/A'
                )}
              </td>
              <td>
                {match.createdAt
                  ? new Date(match.createdAt).toLocaleString()
                  : 'Not available'}
              </td>
              <td>
                <button
                  className={styles.editButton}
                  onClick={() => setEditingMatch(editingMatch === match._id ? null : match._id)}
                >
                  {editingMatch === match._id ? 'Done' : 'Edit'}
                </button>
                <button
                  className={styles.deleteButton}
                  onClick={() => handleDeleteMatch(match._id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className={styles.paginationControls}>
        <button
          onClick={() => handlePageChange(pagination.currentPage - 1)}
          disabled={pagination.currentPage <= 1}
        >
          Previous
        </button>
        <span>
          Page {pagination.currentPage} of {pagination.totalPages}
        </span>
        <button
          onClick={() => handlePageChange(pagination.currentPage + 1)}
          disabled={pagination.currentPage >= pagination.totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default MatchesManagement;