import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from '../styles/UsersManagement.module.css';

const UsersManagement = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalUsers: 0
  });

  // Add token retrieval
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        // Validate token exists
        if (!token) {
          throw new Error('No authentication token found');
        }

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/users`, 
          {
            headers: { 
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            params: {
              page: pagination.currentPage
            }
          }
        );

        // Log full response for debugging
        console.log('Full API Response:', response.data);

        // Validate response structure
        if (!response.data || !response.data.users) {
          throw new Error('Invalid response format');
        }

        // Update users and pagination
        setUsers(response.data.users);
        setPagination({
          currentPage: response.data.currentPage || 1,
          totalPages: response.data.totalPages || 1,
          totalUsers: response.data.totalUsers || 0
        });

        setLoading(false);
      } catch (err) {
        // More detailed error logging
        console.error('Fetch users error:', err);
        setError(err.response?.data?.message || err.message || 'Failed to fetch users');
        setLoading(false);
      }
    };

    fetchUsers();
  }, [token, pagination.currentPage]);

  const handleDeleteUser = async (userId) => {
    try {
      // Validate token and userId
      if (!token) {
        throw new Error('No authentication token found');
      }

      if (!userId) {
        throw new Error('Invalid user ID');
      }

      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/admin/users/${userId}`, 
        {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      // Update users state by filtering out deleted user
      setUsers(prevUsers => 
        prevUsers.filter(user => user._id !== userId)
      );

      // Update total users count
      setPagination(prev => ({
        ...prev,
        totalUsers: prev.totalUsers - 1
      }));
    } catch (err) {
      // More detailed error logging
      console.error('Delete user error:', err);
      setError(err.response?.data?.message || err.message || 'Failed to delete user');
    }
  };

  const handlePageChange = (newPage) => {
    setPagination(prev => ({
      ...prev,
      currentPage: newPage
    }));
  };

  // Render error with more details
  if (error) {
    return (
      <div className={styles.errorContainer}>
        <h2>Error</h2>
        <p>{error}</p>
        <details>
          <summary>Troubleshooting Tips</summary>
          <ul>
            <li>Ensure your backend API is running</li>
            <li>Check your authentication token</li>
            <li>Verify network connectivity</li>
            <li>Check browser console for detailed errors</li>
          </ul>
        </details>
      </div>
    );
  }

  // Loading state
  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.spinner}></div>
        <p>Loading users...</p>
      </div>
    );
  }

  // No users found state
  if (users.length === 0) {
    return (
      <div className={styles.noUsersContainer}>
        <h2>User Management</h2>
        <p>No users found.</p>
      </div>
    );
  }

  return (
    <div className={styles.usersManagement}>
      <h2>User Management</h2>
      <div className={styles.userStats}>
        <p>Total Users: {pagination.totalUsers}</p>
        <p>Page {pagination.currentPage} of {pagination.totalPages}</p>
      </div>
      <table className={styles.userTable}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Gender</th>
            <th>Age</th>
            <th>Location</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user._id}>
              <td>
                <div className={styles.userInfo}>
                  <span>{user.name || 'Unknown'}</span>
                </div>
              </td>
              <td>{user.email || 'N/A'}</td>
              <td>{user.gender || 'N/A'}</td>
              <td>{user.age || 'N/A'}</td>
              <td>
                {user.location 
                  ? `${user.location.city}, ${user.location.country}` 
                  : 'N/A'}
              </td>
              <td>
                <div className={styles.actionButtons}>
                  <button 
                    className={styles.deleteButton}
                    onClick={() => handleDeleteUser(user._id)}
                  >
                    Delete
                  </button>
                  <button 
                    className={styles.viewButton}
                    onClick={() => {/* Add view user details logic */}}
                  >
                    View
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className={styles.paginationControls}>
        <button 
          onClick={() => handlePageChange(pagination.currentPage - 1)}
          disabled={pagination.currentPage <= 1}
        >
          Previous
        </button>
        <span>
          Page {pagination.currentPage} of {pagination.totalPages}
        </span>
        <button 
          onClick={() => handlePageChange(pagination.currentPage + 1)}
          disabled={pagination.currentPage >= pagination.totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default UsersManagement;