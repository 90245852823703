import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Profile from './components/Profile';
import Login from './components/Login';
import Signup from './components/Signup';
import Chat from './components/Chat';
import Inbox from './components/Inbox';
import Discover from './components/Discover';
import ViewProfile from './components/ViewProfile';
import ProfileCompletion from './components/ProfileCompletion';
import ForYou from './components/ForYou';
import LikedYou from './components/LikedYou';
import AdminPanel from './components/AdminPanel';
import PrivateRoute from './components/PrivateRoute';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/profile" element={<Profile />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/chat/:receiverId" element={<Chat />} />
        <Route path="/profile/:userId" element={<ViewProfile />} />
        <Route path="/inbox" element={<Inbox />} />
        <Route path="/completeprofile" element={<ProfileCompletion />} />
        <Route path="/discover" element={<Discover />} />
        <Route path="/likedyou" element={<LikedYou />} />
        <Route path="/foryou" element={<ForYou />} />
        <Route 
          path="/admin" 
          element={
            <PrivateRoute>
              <AdminPanel />
            </PrivateRoute>
          } 
        />
        <Route path="/" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;